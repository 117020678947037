
import { Component, Vue, Prop } from "vue-property-decorator";
import { mdiCheck } from "@mdi/js";
import state from "@/store/driveStore";

/**
 * Pricing tier
 */
@Component({
  name: "PricingTier",
  components: {},
})
export default class Tier extends Vue {
  /**
   * One of the available tiers
   */
  @Prop()
  private tier!: {
    id: number,
    title: string;
    subtitle: string;
    price: string;
    options: string[];
    action: { title: string; action: () => void };
  };
  private mdiCheck = mdiCheck;

  private get activeTier() {
    return state.tier;
  }
}
