
import { Component, Vue, Prop } from "vue-property-decorator";
import TeaserI from "@/entities/Teaser";

/**
 * Generic teaser component
 */
@Component({
  name: "SharedTeaser",
  components: {},
})
export default class Teaser extends Vue {
  /**
   * Teaser content to display
   */
  @Prop()
  private teaser!: TeaserI;
  /**
   * Button text
   */
  @Prop({ default: null })
  private buttonText!: string;
}
