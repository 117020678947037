
import { Component, Vue } from "vue-property-decorator";
import state, { uploadLayer, removeLayer } from "@/store/exploreStore";
import UploadLogic from "@/components/UploadWorkflow/Upload/UploadLogic";
import { mdiUpload } from "@mdi/js";

/**
 * Modal to manage the uploaded shape files
 */
@Component({
  name: "NavBarLayerManager",
  components: {},
})
export default class LayerManager extends Vue {
  private visible: boolean = true;
  private accept: string = ".json,.geojson,.zip";
  private mdiUpload = mdiUpload;

  private headers = [
    { text: "File Name", value: "fileName" },
    { text: "", value: "actions", sortable: false },
  ];

  private get tableData() {
    return state.layers;
  }

  private mounted() {
    UploadLogic.initDropZone(
      "shape-drop-area",
      this.accept,
      this.uploadLayer,
      true
    );
  }

  private openUpload() {
    (this.$refs.input as HTMLInputElement).click();
  }

  private uploadLayers(fileArray: FileList) {
    Array.from(fileArray).forEach((file) => {
      this.uploadLayer(file);
    });
  }

  private uploadLayer(file: File) {
    uploadLayer(file);
    (this.$refs.input as HTMLInputElement).value = "";
  }

  private removeLayer(item: {
    fileName: string;
    id: string;
    data: object | null;
  }) {
    removeLayer(item);
  }

  private close() {
    this.visible = false;
    /**
     * Close the modal
     */
    this.$emit("close");
  }
}
