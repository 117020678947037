
import { Component, Vue } from "vue-property-decorator";
import UploadedFile from "@/entities/UploadedFile";
import CallToAction from "@/components/Home/CallToAction/CallToAction.vue";
import Features from "@/components/Home/Features/Features.vue";
import Examples from "@/components/Home/Examples/Examples.vue";
import GoogleDriveIntegration from "@/components/Home/GoogleDriveIntegration/GoogleDriveIntegration.vue";
import Footer from "@/components/Home/Footer/Footer.vue";
import Tiers from "@/components/Pricing/Tiers/Tiers.vue";

/**
 * Root most component for the home page
 */
@Component({
  name: "HomeHome",
  components: {
    CallToAction,
    Features,
    Examples,
    Tiers,
    GoogleDriveIntegration,
    Footer,
  },
})
export default class Home extends Vue {}
