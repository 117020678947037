
import { Component, Vue, Prop } from "vue-property-decorator";

/**
 * Create some text that is editable upon double click.
 */
@Component({
  name: "SharedDoubleClickEditText",
  components: {},
})
export default class DoubleClickEditText extends Vue {
  /**
   * Initial text
   */
  @Prop({ default: "" })
  private value!: string;
  private isEditing: boolean = false;
  private valueAux: string | null = null;

  private startEdit() {
    this.isEditing = true;
    this.valueAux = this.value;
    this.$nextTick(() => {
      (this.$refs.textEdit as HTMLInputElement).focus();
    });
  }

  private saveEdit() {
    this.isEditing = false;
    if (!this.valueAux) {
      this.valueAux = "Untitled File";
    }
    /**
     * Update the value
     * 
     * @type {string}
     */
    this.$emit("input", this.valueAux);
  }

  private handleBlur() {
    this.isEditing = false;
  }
}
