
import { Component, Vue } from "vue-property-decorator";
import { mdiGit, mdiLinkedin } from "@mdi/js";
import { mdiGithub } from "@mdi/js";
import { features, examples } from "@/entities/data";

/**
 * Footer of website
 */
@Component({
  name: "HomeFooter",
  components: {},
})
export default class Footer extends Vue {
  private links = {
    product: [
      { label: "Features", link: "/features" },
      { label: "Examples", link: "/examples" },
      { label: "Pricing", link: "/pricing" },
      { label: "Account", link: "/account" },
      { label: "Uploads", link: "/uploads" },
    ],
    features: features.map((feature) => {
      return {
        label: feature.title,
        link: feature.feature.url,
      };
    }),
    examples: examples.map((feature) => {
      return {
        label: feature.title,
        link: feature.feature.url,
      };
    }),
  };

  private socialLinks = [
    { icon: mdiLinkedin, link: "https://www.linkedin.com/company/tableandmap" },
    { icon: mdiGithub, link: "https://github.com/tjgambs/tableandmap" },
  ];

  private get currentYear() {
    return new Date().getFullYear();
  }
}
