
import { Component, Vue } from "vue-property-decorator";

/**
 * Explain the google drive integration
 */
@Component({
  name: "HomeGoogleDriveIntegration",
  components: {},
})
export default class GoogleDriveIntegration extends Vue {
  private steps = [
    "Sign in with your Google account",
    "Upload your files",
    "Apply filters, draw shapes and upload shapefiles",
    "Everything is saved to your Google Drive",
    "Share with co-workers and revisit at a later time",
  ];
}
