
import { Component, Vue } from "vue-property-decorator";
import Loading from "@/components/Shared/Loading/Loading.vue";
import lambdaApi from "@/api/lambda";

/**
 * Textarea that allows the user to paste in a csv
 */
@Component({
  name: "UploadWorkflowUploadPasteModal",
  components: {
    Loading
  },
})
export default class PasteModal extends Vue {
  private text: string = "";
  private datasetUrl: string = "";
  private description: string =
    "Copy a dataset from an excel or csv file (including the header) or json file and paste it into the text box below. You may also enter a URL to the dataset and we will retrieve it for you.";
  private placeholder: string =
    "Street Address,City,State,Zip Code,Latitude,Longitude\n1600 Amphitheatre Pkwy,Mountain View,CA,94043,37.423432,-122.078865";
  private visible: boolean = true;
  private isLoading: boolean = false;

  private get finishIsDisabled() {
    return this.text === "" && this.datasetUrl === "";
  }

  private closeModal() {
    /**
     * Close the modal
     */
    this.$emit("close-modal");
  }

  private uploadText() {
    /**
     * Emit the uploaded text
     *
     * @type {{string}}
     */
    if (this.datasetUrl) {
      this.isLoading = true;
      lambdaApi.getPastedUrl(this.datasetUrl).then(response => {
        this.$emit("upload-text", response);
        this.closeModal();
      }).finally(() => {
        this.isLoading = false;
      });
    } else {
      this.$emit("upload-text", this.text);
      this.closeModal();
    }
  }

  private beforeDestroy() {
    this.closeModal();
  }
}
