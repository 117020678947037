
import { Component, Vue } from "vue-property-decorator";
import NavBar from "@/components/NavBar/NavBar.vue";
import state from "@/store/exploreStore";

/**
 * Root of project
 */
@Component({
  name: "App",
  components: {
    NavBar,
  },
})
export default class App extends Vue {
  private get drawerAllowed() {
    return state.uploadedFile !== null && this.$route.name === "Explore";
  }
  private get displayNav() {
    return this.$route.name !== null && this.$route.name !== "Embed";
  }
}
