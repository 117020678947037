
import { Component, Vue } from "vue-property-decorator";
import Preview from "@/components/Home/Preview/Preview.vue";

/**
 * Display the features that are currently available
 */
@Component({
  name: "HomeFeatures",
  components: {
    Preview,
  },
})
export default class Features extends Vue {
  private title = "Plot Addresses on a Map";
  private subtitle =
    "Upload an excel, csv or json file with addresses or latitudes and longitudes to plot your data on an interactive map.";
  private cards = [
    {
      title: "Unlimited Geocoding",
      description:
        "Visualize your location data without a latitude or longitude. Just select the location columns and we take care of the rest.",
      learnMorePath: "/features/unlimited-geocoding",
    },
    {
      title: "GeoJSON and Shapefile Layers",
      description:
        "Upload GeoJSON and Shapefiles to visualize the regions markers fall within.",
      learnMorePath: "/features/geojson-and-shapefile-layers",
    },
    {
      title: "Categorical Grouping",
      description:
        "Select a categorical column to group by and see the marker colors update, one color for each category.",
      learnMorePath: "/features/categorical-grouping",
    },
  ];

  private get cardWidth(): string {
    if (this.$vuetify.breakpoint.xs) {
      return "100%";
    }
    return "calc(33% - 15px)";
  }
}
