
import { Component, Vue } from "vue-property-decorator";
import Teaser from "@/components/Shared/Teaser/Teaser.vue";
import Loading from "@/components/Shared/Loading/Loading.vue";
import ExampleTeaserI from "@/entities/ExampleTeaser";
import { examples } from "@/entities/data";

/**
 * Display a preview of the available examples that are currently available
 */
@Component({
  name: "HomeExamples",
  components: {
    Teaser,
    Loading,
  },
})
export default class Examples extends Vue {
  private loading: boolean = false;
  private examples = examples.slice(0, 3);

  private async preview(teaser: ExampleTeaserI) {
    /**
     * Open the selected teaser in the tool
     *
     * @type {ExampleTeaserI}
     */
    this.$emit("preview", teaser);
  }
}
