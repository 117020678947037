
import { Component, Vue } from "vue-property-decorator";

/**
 * Modal to allow customers to copy the embed code
 */
@Component({
  name: "NavBarEmbedCode",
  components: {},
})
export default class EmbedCode extends Vue {
  private visible: boolean = true;
  private get embedCode() {
    return `<iframe height="500" width="500" src="${process.env.VUE_APP_BASE_URL}/embed/${this.$route.params.fileId}"></iframe>`;
  }

  private close() {
    this.visible = false;
    /**
     * Close the modal
     */
    this.$emit("close");
  }
}
